import styled from "@emotion/styled"
import { graphql } from "gatsby"
import React from "react"
import { BigPillButton } from "../components/button"
import Card from "../components/card"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography, { TypographyMarkdown } from "../components/typography"
import { useCookieConsentContext } from "../contexts/cookieConsent"
import colors from "../styles/colors"
import { DesktopMediaQuery, MobileMediaQuery } from "../styles/constants"

const CookieList = styled.div`
  border: 2px solid ${colors.darkgrey};
  border-radius: 24px;
  width: 100%;
  padding: 24px;
`

const Row = styled.div`
  display: flex;
  padding-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid ${colors.darkgrey};

  ${MobileMediaQuery} {
    flex-direction: column;
  }
`
const Headers = styled.div`
  display: flex;
  font-weight: 600;
  border-bottom: 1px solid ${colors.darkgrey};
  font-size: 20px;

  ${MobileMediaQuery} {
    display: none;
  }
`

const MobileHeader = styled.div`
  flex: 1;
  font-weight: 600;
  ${DesktopMediaQuery} {
    display: none;
  }
`

const Item = styled.div`
  margin-right: 15px;
  ${MobileMediaQuery} {
    display: flex;
    margin: 5px 0;
  }
`

const ItemText = styled.div`
  flex: 2;
`

const cookieList = [
  { name: "Necessary", cookies: [] },
  {
    name: "Preferences cookies",
    cookies: [
      {
        name: "ajs_anonymous_id",

        purpose:
          "Used for Analytics and help count how many people visit a certain site by tracking if you have visited before.",
        expiry: "1 year",
        provider: ".mavenoid.com",
      },
    ],
  },
  {
    name: "Statistics cookies",
    cookies: [
      {
        name: "_ga",

        purpose:
          "This cookie name is associated with Google Universal Analytics - which is a significant update to Google's more commonly used analytics service. This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports.",
        expiry: "2 years",
        provider: ".mavenoid.com",
      },
      {
        name: "_gid",

        purpose:
          "Registers a unique ID that is used to generate statistical data on how the visitor uses the website.",
        expiry: "1 day",
        provider: ".mavenoid.com",
      },
      {
        name: "_gat_XXXXXXX",

        purpose: "Used by Google Analytics to throttle request rate",
        expiry: "1 minute",
        provider: ".mavenoid.com",
      },
    ],
  },
  {
    name: "Marketing cookies",
    cookies: [
      {
        name: "fr",

        purpose:
          "Contains browser and user unique ID combination, used for targeted advertising.",
        expiry: "3 months",
        provider: ".facebook.com",
      },
      {
        name: "UserMatchHistory",

        purpose:
          "This cookie is used to track visitors so that more relevant ads can be presented based on the visitor's preferences",
        expiry: "30 days",
        provider: ".linkedin.com",
      },
      {
        name: "AnalyticsSyncHistory",

        purpose: "Targeting/Advertising",
        expiry: "30 days",
        provider: ".linkedin.com",
      },
      {
        name: "lang",

        purpose: "",
        expiry: "Session",
        provider: ".linkedin.com",
      },
      {
        name: "bcookie",

        purpose:
          "This is a Microsoft MSN 1st party cookie for sharing the content of the website via social media.",
        expiry: "2 years",
        provider: ".linkedin.com",
      },
      {
        name: "lang",

        purpose: "",
        expiry: "Session",
        provider: ".linkedin.com",
      },
      {
        name: "lidc",

        purpose:
          "This is a Microsoft MSN 1st party cookie that ensures the proper functioning of this website. ",
        expiry: "1 day",
        provider: ".linkedin.com",
      },
    ],
  },
]

const ProductPage = ({ data }) => {
  const { open, close } = useCookieConsentContext()

  return (
    <Layout bg="white" newsletter={null}>
      <SEO title="Cookie policy" />
      <Card variant="white">
        <TypographyMarkdown style={{ maxWidth: "660px" }}>
          <span
            dangerouslySetInnerHTML={{
              __html: data.file.childMarkdownRemark.html,
            }}
          />
        </TypographyMarkdown>
        <BigPillButton
          variant="darkgrey"
          style={{ width: 330, marginTop: "4rem" }}
          onClick={open}
        >
          Change cookie preferences
        </BigPillButton>
        <BigPillButton
          variant="darkgrey"
          style={{ width: 330, marginTop: "22px", marginBottom: "4rem" }}
          onClick={() => close([])}
        >
          Withdraw your consent
        </BigPillButton>
        <Typography variant="h2" style={{ marginTop: "3rem" }}>
          List of cookies
        </Typography>
        <CookieList style={{ marginTop: "2rem" }}>
          <Headers>
            <Item style={{ flex: 2 }}>Name</Item>
            <Item style={{ flex: 2 }}>Provider</Item>
            <Item style={{ flex: 3 }}>Purpose</Item>
            <Item style={{ flex: 1 }}>Expiry</Item>
          </Headers>
          {cookieList
            .filter(x => x.cookies.length > 0)
            .map(group => (
              <>
                <Typography
                  variant="subtitleLarge"
                  style={{ marginTop: "2rem", display: "inline-block" }}
                >
                  {group.name}
                </Typography>
                {group.cookies.map(cookie => (
                  <Row>
                    <Item style={{ flex: 2 }}>
                      <MobileHeader>Name</MobileHeader>
                      <ItemText>{cookie.name}</ItemText>
                    </Item>
                    <Item style={{ flex: 2 }}>
                      <MobileHeader>Provider</MobileHeader>
                      <ItemText>{cookie.provider}</ItemText>
                    </Item>
                    <Item style={{ flex: 3 }}>
                      <MobileHeader>Purpose</MobileHeader>
                      <ItemText>{cookie.purpose}</ItemText>
                    </Item>
                    <Item style={{ flex: 1 }}>
                      <MobileHeader>Expiry</MobileHeader>
                      <ItemText>{cookie.expiry}</ItemText>
                    </Item>
                  </Row>
                ))}
              </>
            ))}
        </CookieList>
      </Card>
    </Layout>
  )
}

export default ProductPage

export const query = graphql`
  query MyQuery {
    file(sourceInstanceName: { eq: "content" }) {
      name
      childMarkdownRemark {
        html
      }
    }
  }
`
